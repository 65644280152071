<template>
    <v-card class="rounded-lg overflow-hidden" elevation="1">
        <v-card-text>
            <v-fade-transition mode="out-in" hide-on-leave>
                <div v-show="!loading">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <div class="text-subtitle-2 text-medium-emphasis">Usuarios en Riesgo</div>
                            <div class="text-h4 font-weight-bold">
                                {{ usersAtRisk.current }}
                            </div>
                        </div>
                        <v-icon :color="trendColor" size="48">
                            {{ trendIcon }}
                        </v-icon>
                    </div>

                    <div class="mt-2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <span :class="trendColor + '--text'" class="font-weight-bold">
                                        {{ trendPercentage }}%
                                    </span>
                                    <span class="text-medium-emphasis"> vs mes anterior</span>
                                </span>
                            </template>
                            <span>Mes anterior: {{ usersAtRisk.previous }} usuarios</span>
                        </v-tooltip>
                    </div>

                    <div class="text-caption text-medium-emphasis mt-2">
                        Sin entrenamientos ni licencias activas este mes
                    </div>
                </div>
            </v-fade-transition>

            <v-fade-transition mode="out-in" hide-on-leave>
                <div v-show="loading" class="loader-container">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <v-skeleton-loader type="text" width="100" class="mb-2" />
                            <v-skeleton-loader type="heading" width="160" />
                        </div>
                        <v-skeleton-loader type="avatar" width="48" height="48" />
                    </div>
                    <div class="mt-2">
                        <v-skeleton-loader type="text" width="140" />
                    </div>
                </div>
            </v-fade-transition>
        </v-card-text>
    </v-card>
</template>

<script>
import DashboardService from '@/services/DashboardService';

export default {
    name: 'UsersAtRiskCard',

    data() {
        return {
            loading: true,
            usersAtRisk: {
                current: 0,
                previous: 0
            }
        };
    },

    mounted() {
        this.fetchData();
    },

    computed: {
        trend() {
            if (!this.usersAtRisk.previous) return 0;
            return ((this.usersAtRisk.current - this.usersAtRisk.previous) / this.usersAtRisk.previous) * 100;
        },
        trendPercentage() {
            return Math.abs(this.trend).toFixed(1);
        },
        trendColor() {
            // Reverse the color logic since fewer users at risk is better
            return this.trend > 0 ? 'error' : 'success';
        },
        trendIcon() {
            return this.trend > 0 ? 'mdi-trending-up' : 'mdi-trending-down';
        }
    },

    methods: {
        async fetchData() {
            this.loading = true;
            try {
                const dashboardData = await DashboardService.getDashboardData();
                this.usersAtRisk = dashboardData.usersAtRisk;
            } catch (error) {
                console.error('Error fetching users at risk data:', error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>