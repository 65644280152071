<template>
    <v-card class="rounded-lg overflow-hidden" elevation="1">
        <v-card-text>
            <v-fade-transition mode="out-in" hide-on-leave>
                <div v-show="!loading">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <div class="text-subtitle-2 text-medium-emphasis">Satisfacción General</div>
                            <div class="text-h4 font-weight-bold">
                                {{ (currentSatisfaction * 20).toFixed(1) }}%
                            </div>
                        </div>
                        <v-icon :color="satisfactionChange >= 0 ? 'success' : 'error'" size="48">
                            {{ satisfactionChange >= 0 ? 'mdi-trending-up' : 'mdi-trending-down' }}
                        </v-icon>
                    </div>

                    <div class="mt-2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <span :class="satisfactionChange >= 0 ? 'success--text' : 'error--text'"
                                        class="font-weight-bold">
                                        {{ Math.abs(satisfactionChange).toFixed(1) }}%
                                    </span>
                                    <span class="text-medium-emphasis"> vs mes anterior</span>
                                </span>
                            </template>
                            <span>Mes anterior: {{ (previousSatisfaction * 20).toFixed(1) }}%</span>
                        </v-tooltip>
                    </div>

                    <!-- Per Question Satisfaction -->
                    <div class="mt-4">
                        <div class="text-caption text-medium-emphasis mb-2">Satisfacción por pregunta</div>
                        <div v-for="(question, index) in questionSatisfaction" :key="index" class="mb-2">
                            <div class="d-flex justify-space-between align-center">
                                <div class="text-caption" style="width: 70%">
                                    {{ question.text }}
                                </div>
                                <div class="text-right">
                                    <span :class="getSatisfactionColor(question.rating)">
                                        {{ (question.rating * 20).toFixed(1) }}%
                                    </span>
                                </div>
                            </div>
                            <v-progress-linear :value="question.rating * 20"
                                :color="getSatisfactionColor(question.rating, true)" height="4"
                                rounded></v-progress-linear>
                        </div>
                    </div>

                    <!-- Comments Summary -->
                    <div class="mt-4">
                        <div class="d-flex align-center justify-space-between mb-1">
                            <span class="text-caption">Comentarios mes</span>
                            <div>
                                <v-chip color="success" small class="mr-1" @click="showComments('Positivo')"
                                    :disabled="!currentPositive">
                                    <span class="d-none d-sm-inline">Ver</span>
                                    <v-icon class="mx-1" small>mdi-emoticon-happy-outline</v-icon>
                                    {{ currentPositive }}

                                </v-chip>
                                <v-chip color="grey" small class="mr-1" @click="showComments('Neutral')"
                                    :disabled="!currentNeutral">
                                    <span class="d-none d-sm-inline">Ver</span>
                                    <v-icon class="mx-1" small>mdi-emoticon-neutral-outline</v-icon>
                                    {{ currentNeutral }}
                                </v-chip>
                                <v-chip color="error" small @click="showComments('Negativo')"
                                    :disabled="!currentNegative">
                                    <span class="d-none d-sm-inline">Ver</span>
                                    <v-icon class="mx-1" small>mdi-emoticon-sad-outline</v-icon>
                                    {{ currentNegative }}
                                </v-chip>
                            </div>
                        </div>
                    </div>
                </div>
            </v-fade-transition>

            <v-fade-transition mode="out-in" hide-on-leave>
                <div v-show="loading" class="loader-container">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <v-skeleton-loader type="text" width="120" class="mb-2" />
                            <v-skeleton-loader type="heading" width="160" />
                        </div>
                        <v-skeleton-loader type="avatar" width="48" height="48" />
                    </div>
                    <div class="mt-2">
                        <v-skeleton-loader type="text" width="140" />
                    </div>
                </div>
            </v-fade-transition>
        </v-card-text>

        <!-- Comments Modal -->
        <v-dialog v-model="showModal" max-width="600" :fullscreen="$vuetify.breakpoint.smAndDown">
            <v-card>
                <v-card-title class="d-flex align-center">
                    <v-icon :color="getModalColor" class="mr-2">
                        {{ getModalIcon }}
                    </v-icon>
                    Comentarios {{ selectedSentiment }}s
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showModal = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="pt-4">
                    <div v-if="filteredComments.length">
                        <div v-for="(comment, index) in filteredComments" :key="index" class="mb-4">
                            <div class="chat-message d-flex mb-3">
                                <v-badge :color="comment.trainingComment === true ? 'amber' : 'info'" bordered bottom
                                    offset-x="10" offset-y="10">
                                    <template v-slot:badge>
                                        <v-icon x-small style="margin-top: -2px;">
                                            {{ comment.trainingComment === true ? 'mdi-dumbbell' : 'mdi-clipboard-text'
                                            }}
                                        </v-icon>
                                    </template>

                                    <v-avatar class="user-avatar" size="32">
                                        <UserImage :user="{ id: comment.userId }" small />
                                    </v-avatar>
                                </v-badge>

                                <div class="chat-bubble ml-3">
                                    <div class="chat-header d-flex align-center">
                                        <span class="font-weight-medium">{{ comment.userId }}</span>
                                        <v-spacer></v-spacer>
                                        <span class="text-caption text-medium-emphasis">
                                            {{ formatDate(comment.date) }}
                                        </span>
                                    </div>
                                    <div class="chat-content mt-1">
                                        {{ comment.text }}
                                        <div v-if="comment.trainingComment === true" class="mt-2">
                                            <div class="d-flex align-center">
                                                <span class="text-caption mr-2">Valoración:</span>
                                                <v-rating v-model="comment.rating" readonly dense small half-increments
                                                    color="amber" background-color="amber lighten-3"
                                                    :length="5"></v-rating>
                                                <span class="ml-2 text-caption">{{ comment.rating }}/5</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center py-4 text-body-1 grey--text">
                        No hay comentarios para mostrar
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import DashboardService from '@/services/DashboardService'
import moment from 'moment'
import UserImage from '@/components/profile/UserImage.vue'

export default {
    name: 'SatisfactionCard',

    components: {
        UserImage
    },

    data() {
        return {
            loading: true,
            currentSatisfaction: 0,
            previousSatisfaction: 0,
            satisfactionChange: 0,
            currentPositive: 0,
            currentNeutral: 0,
            currentNegative: 0,
            questionSatisfaction: [],
            showModal: false,
            selectedSentiment: '',
            comments: []
        }
    },

    computed: {
        filteredComments() {
            return this.comments
                .filter(comment => comment.sentiment === this.selectedSentiment)
                .sort((a, b) => b.date.toDate() - a.date.toDate())
                .slice(0, 50)
        },
        getModalColor() {
            switch (this.selectedSentiment) {
                case 'Positivo': return 'success'
                case 'Neutral': return 'grey'
                case 'Negativo': return 'error'
                default: return ''
            }
        },
        getModalIcon() {
            switch (this.selectedSentiment) {
                case 'Positivo': return 'mdi-emoticon-happy-outline'
                case 'Neutral': return 'mdi-emoticon-neutral-outline'
                case 'Negativo': return 'mdi-emoticon-sad-outline'
                default: return ''
            }
        }
    },

    mounted() {
        this.fetchData()
    },

    methods: {
        formatDate(date) {
            return moment(date.toDate()).format('DD/MM/YYYY HH:mm')
        },

        showComments(sentiment) {
            this.selectedSentiment = sentiment
            this.showModal = true
        },

        getSatisfactionColor(rating, isProgress = false) {
            const value = rating * 20
            if (value >= 80) return isProgress ? 'success' : 'success--text'
            if (value >= 60) return isProgress ? 'warning darken-1' : 'warning--text darken-1'
            if (value >= 40) return isProgress ? 'warning darken-2' : 'warning--text darken-2'
            if (value >= 20) return isProgress ? 'error lighten-1' : 'error--text lighten-1'
            return isProgress ? 'error darken-1' : 'error--text darken-1'
        },

        async fetchData() {
            this.loading = true
            try {
                const dashboardData = await DashboardService.getDashboardData()
                const satisfaction = dashboardData.satisfaction

                this.currentSatisfaction = satisfaction.current.average
                this.previousSatisfaction = satisfaction.previous.average
                this.questionSatisfaction = satisfaction.current.questionSatisfaction
                this.comments = satisfaction.current.comments

                const { Positivo, Neutral, Negativo } = satisfaction.current.sentiments
                this.currentPositive = Positivo
                this.currentNeutral = Neutral
                this.currentNegative = Negativo

                // Calculate percentage change
                this.satisfactionChange = this.previousSatisfaction === 0
                    ? 0
                    : ((this.currentSatisfaction - this.previousSatisfaction) /
                        Math.abs(this.previousSatisfaction)) * 100

            } catch (error) {
                console.error('Error fetching satisfaction data:', error)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style scoped>
.text-caption {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.v-chip {
    cursor: pointer;
}

.v-chip--disabled {
    cursor: default;
}

.chat-message {
    align-items: flex-start;
}

.chat-bubble {
    flex: 1;
    padding: 10px 14px;
    background-color: var(--v-surface-base);
    border-radius: 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.theme--dark .chat-bubble {
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.theme--light .chat-bubble {
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.chat-header {
    margin-bottom: 2px;
}

.chat-content {
    word-break: break-word;
}

.user-avatar {
    display: flex;
    align-items: center;
}
</style>